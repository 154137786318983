<template>
  <div class="closeBetaviewDetail">
    <el-form
      class="dialogForm"
      size="small"
      label-width="200px"
      style="height: 500px; overflow: scroll; overflow-x: hidden"
    >
      <el-form-item label="测试时间" class="requireClass">
        <el-date-picker
          disabled
          class="date-test-application"
          size="small"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetime"
          prefix-icon="el-icon-date"
          v-model="closeBetaInfo.startTime"
          placeholder="选择日期时间"
        >
        </el-date-picker>
        <span class="mhor10">至</span>
        <el-date-picker
          disabled
          class="date-test-application"
          size="small"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetime"
          prefix-icon="el-icon-date"
          v-model="closeBetaInfo.endTime"
          placeholder="选择日期时间"
        >
        </el-date-picker>
        <el-tooltip
          content="请按实际关服时间填写。到期后将自动下架，如需提前下架，请在版本信息中申请下架。"
          placement="top"
          effect="light"
        >
          <i
            class="el-icon-warning-outline tooltip-position"
            style="font-size: 20px; color: rgb(204, 204, 204)"
          ></i>
        </el-tooltip>
      </el-form-item>
      <el-form-item label="预计首发时间" class="requireClass">
        <el-date-picker
          disabled
          class="date-firstPublishDate"
          size="small"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetime"
          prefix-icon="el-icon-date"
          v-model="closeBetaInfo.firstLaunchTime"
          placeholder="选择日期时间"
        >
        </el-date-picker>
        <el-tooltip
          content="建议与您的游戏开服时间一致。此时间为本地时间。"
          placement="top"
          effect="light"
        >
          <i
            class="el-icon-warning-outline tooltip-position"
            style="font-size: 20px; color: rgb(204, 204, 204)"
          ></i>
        </el-tooltip>
      </el-form-item>
      <el-form-item label="是否删档" class="requireClass">
        <el-radio-group
          disabled
          v-model="closeBetaInfo.deleteFiles"
          class="isDeleteArchive"
        >
          <el-radio
            v-for="item in booleanList"
            :key="item.key"
            :label="item.key"
            >{{ item.value }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否计费" class="requireClass">
        <el-radio-group
          disabled
          v-model="closeBetaInfo.isBill"
          class="isDeleteArchive"
        >
          <el-radio
            v-for="item in booleanList"
            :key="item.key"
            :label="item.key"
            >{{ item.value }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="返还策略"
        class="requireClass"
        v-if="closeBetaInfo.deleteFiles && closeBetaInfo.isBill"
      >
        <el-input
          disabled
          size="small"
          type="number"
          v-model.number="closeBetaInfo.returnStrategyMin"
          style="width: 200px"
          ><span slot="suffix">%</span></el-input
        >
        <span> ~ </span>
        <el-input
          disabled
          size="small"
          type="number"
          v-model.number="closeBetaInfo.returnStrategyMax"
          style="width: 200px"
          ><span slot="suffix">%</span></el-input
        >
      </el-form-item>
      <el-form-item label="发布国家及地区：" class="requireClass">
        <el-cascader
          clearable
          size="small"
          :disabled="true"
          :options="areaList"
          v-model="releaseArea"
          placeholder="请选择发布国家或地区"
          :props="areaProps"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="研发公司">
        <el-input
          disabled
          v-model="closeBetaInfo.company"
          show-word-limit
          maxlength="50"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系人及联系方式" class="requireClass">
        <el-input
          disabled
          type="textarea"
          :autosize="{ minRows: 6, maxRows: 6 }"
          show-word-limit
          maxlength="100"
          v-model="closeBetaInfo.communication"
          suffix-icon="el-icon-date"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input
          disabled
          type="textarea"
          :autosize="{ minRows: 8, maxRows: 8 }"
          v-model="closeBetaInfo.remark"
          show-word-limit
          maxlength="200"
          autocomplete="off"
        >
        </el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { booleanList } from "@/common/constant/constant.js";
export default {
  name: "viewDetail",
  props: {
    checkData: {
      type: Object,
      default() {
        return {};
      },
    },
    closeBetaInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    propReleaseArea: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  created() {
    this.getArrReleaseArea(this.propReleaseArea);
    this.$store.dispatch("updateAreaListList");
  },
  data() {
    return {
      booleanList,
      releaseArea: [],
      areaProps: {
        value: "countryId",
        label: "countryName",
        children: "honorAppCountryList",
        multiple: true,
      },
    };
  },
  computed: {
    ...mapGetters(["areaList"]),
  },
  methods: {
    // 根据id获取完整的id数组（包含上下级关系）
    async getArrReleaseArea(idList = []) {
      const areaList = await this.$store.dispatch("updateAreaListList");
      const arr = [];
      if (!(idList.length === 0 || areaList.length === 0)) {
        idList.map((key, index) => {
          const parentkey = this.getParentKey(key, areaList);
          arr[index] = [parentkey, key];
        });
      }
      this.releaseArea = arr;
    },
    // 根据传入的孩子节点的key获取父节点key
    getParentKey(childrenKey, list = []) {
      let parentItem = "";
      for (let item of list) {
        const childrenList = item[this.areaProps.children];
        if (childrenList && childrenList.length !== 0) {
          const obj = childrenList.find(
            (item1) => item1[this.areaProps.value] === childrenKey
          );
          if (obj) {
            parentItem = item;
            break;
          }
        }
      }
      return parentItem[this.areaProps.value];
    },
  },
  watch: {
    propReleaseArea(idList) {
      this.getArrReleaseArea(idList);
    },
  },
};
</script>
<style scoped lang="scss">
.closeBetaviewDetail {
  .release-title {
    line-height: 28px;
    font-size: 20px;
    margin-bottom: 20px;
  }
  /deep/.requireClass {
    .el-form-item__label::before {
      content: "*";
      color: #f56c6c;
      margin-right: 4px;
    }
  }
}
</style>
<style lang="scss">
.dialogForm {
  .el-input,
  .el-textarea {
    display: inline-block;
    width: 300px;
  }
  .formItemRemark {
    font-size: 12px;
    // width:700px;
  }
  .el-input--prefix .el-input__inner {
    padding-left: 12px;
    padding-right: 12px;
  }
  .el-date-editor .el-input__prefix {
    left: auto;
    right: 5px;
  }
  .date-test-application {
    width: 200px;
  }
  .date-firstPublishDate {
    width: 434px !important;
  }
}
</style>
